import { Box, MenuItem, Stack, Typography } from '@mui/material';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { RHFSelect } from '../../../../components/shared/formFieldRelated/RHFSelect';
import { COLORS } from '../../../../theme/pallete';
import { TYPOGRAPHY } from '../../../../theme/typography';
import
{
  BookRideFieldNames,
  GetServiceProviderAndVehicles,
  PassCoverageTypes,
  RequestVehicleClassType,
  ServiceProviderVehicleClassType,
  ServiceProviderWithVehicleClasses,
  TripTypes,
  VehicleClassTypeResponse,
} from '../../types';

import { useProgramPassContext } from 'pages/ProgramPassContext';
import { RouterContextType } from 'pages/bookRide/bookRidePage';
import { createPortal } from 'react-dom';
import { useFormContext } from 'react-hook-form';
import { useLocation, useMatch, useNavigate, useOutletContext } from 'react-router-dom';
import
{
  FieldsByCurrTripTypeType,
  SurchargesList,
  VehicleClassesBasePoolType,
  VehicleClassesPoolType,
  VehicleClassesToQuotePoolType,
} from 'shared.types';
import AppButton from '../../../../components/shared/AppButton';
import SvgIcon from '../../../../components/shared/SvgIcon';
import { ENDPOINTS } from '../../../../services/Endpoints';
import { ServiceTypes, useBaseService } from '../../../../services/useBaseService';
import { buildQueryString } from '../../../../utils/string';
import FullScreenLoader from './FullScreenLoader';
import VehicleClassItem from './VehicleClassItem';
import { useGetFieldsByCurrTripType } from './utils';

const getSelectFieldStyles = (iconName: string): Record<string, React.CSSProperties> =>
{
  return {
    '.MuiSelect-select': {
      border: `1px solid ${COLORS.GREY_LIGHT_5}`,
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      height: '36px!important',
      width: '90px',
      padding: '0 0 0 38px!important',
      backgroundImage: `url("/assets/icons/${iconName}"), url("/assets/icons/icon_arrowDown.svg")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '8px center, calc(100% - 5px) center',
      backgroundSize: '18px, 20px',
    },
    'svg.MuiSvgIcon-root': {
      display: 'none',
    },
  };
};

let pageTimeOuts: NodeJS.Timeout[] = [];
const pendingRequests = new Set();

const CreateEndpointWithQueryParamsForRequestingVehicleClasses = ({
  passengersAmount,
  luggageAmount,
  programId,
  serviceProviderId,
  pickupDate,
  pickupTime,
  estimatedTimeInHours,
}: {
  passengersAmount: number;
  luggageAmount: number;
  programId?: string;
  serviceProviderId: string;
  pickupDate: string;
  pickupTime: string;
  estimatedTimeInHours: number;
}): string =>
{
  const requestBody = {
    NumberOfPassengers: passengersAmount,
    AmountOfLuggage: luggageAmount,
    ...(programId && {
      BusinessProgramId: programId,
    }),
    PickupTime: `${pickupDate}T${pickupTime}:00`,
    Hours: estimatedTimeInHours,
  };

  return `${ENDPOINTS.GetVehicleClassesByProviderId(serviceProviderId as string)}?${buildQueryString(requestBody)}`;
};

const VehicleClassStep2 = (): ReactElement =>
{
  const { setValue, watch, trigger } = useFormContext();
  const { post, abortQueries, get } = useBaseService({
    type: ServiceTypes.RideAPI,
  });
  const { programPass } = useProgramPassContext();
  const {
    vehicleClassesToQuote,
    setVehicleClassesToQuote,
    serviceProviderId,
    setServiceProviderId,
    vehicleClassesToQuotePool,
    setVehicleClassesToQuotePool,
    vehicleClassesPool,
    setVehicleClassesPool,
    lastGooglePlacesIdPickup,
    setLastGooglePlacesIdPickup,
  } = useOutletContext<RouterContextType>();
  const [isGettingServiceProvider, setIsGettingServiceProvider] = useState(false);

  const [onDemandLoading, setOnDemandLoading] = useState(false);

  const [canRetrieveQuotes, setCanRetrieveQuotes] = useState(false);

  const getFieldsByCurrTripType = useGetFieldsByCurrTripType();

  const navigate = useNavigate();
  const location = useLocation();
  const [
    tripType,
    pickupAddress,
    dropoffAddress,
    passengersAmount,
    luggageAmount,
    pickupDate,
    pickupTime,
    returnPickupDate,
    returnPickupTime,
    estimatedTimeInHours,
  ] = watch([
    BookRideFieldNames.TRIP_TYPE,
    BookRideFieldNames.PICKUP_ADDRESS,
    BookRideFieldNames.DROPOFF_ADDRESS,
    BookRideFieldNames.PASSENGERS_AMOUNT,
    BookRideFieldNames.LUGGAGE_AMOUNT,
    BookRideFieldNames.PICKUP_DATE,
    BookRideFieldNames.PICKUP_TIME,
    BookRideFieldNames.RETURN_PICKUP_DATE,
    BookRideFieldNames.RETURN_PICKUP_TIME,
    BookRideFieldNames.ESTIMATED_TIME_IN_HOURS,
  ]);

  const [vehicleClasses, setVehicleClasses] = useState<VehicleClassTypeResponse>();
  const [isBackendError, setIsBackendError] = React.useState(false);
  const match = useMatch('/pass/:code/*');

  const isCurrCombinationInPool = useCallback(
    (pool: VehicleClassesBasePoolType[]): VehicleClassesBasePoolType | undefined =>
    {
      const fieldsCurrTripType = getFieldsByCurrTripType();
      const keys = Object.keys(fieldsCurrTripType ?? {});

      return pool.find((poolItem) =>
      {
        return keys.every((key) =>
        {
          const theKey = key as keyof FieldsByCurrTripTypeType;
          return (
            poolItem.serviceProviderId === serviceProviderId &&
            poolItem.luggageAmount === luggageAmount &&
            poolItem.passengersAmount === passengersAmount &&
            JSON.stringify(poolItem[theKey]) === JSON.stringify(fieldsCurrTripType?.[theKey])
          );
        });
      });
    },
    [getFieldsByCurrTripType, serviceProviderId, luggageAmount, passengersAmount],
  );

  const isCurrCombinationInPool2 = useCallback(
    (pool: VehicleClassesBasePoolType[]): VehicleClassesBasePoolType[] | undefined =>
    {
      const fieldsCurrTripType = getFieldsByCurrTripType();
      const keys = Object.keys(fieldsCurrTripType ?? {});

      return pool?.filter((poolItem) =>
      {
        return keys.every((key) =>
        {
          const theKey = key as keyof FieldsByCurrTripTypeType;
          return (
            poolItem.serviceProviderId === serviceProviderId &&
            poolItem.luggageAmount === luggageAmount &&
            poolItem.passengersAmount === passengersAmount &&
            JSON.stringify(poolItem[theKey]) === JSON.stringify(fieldsCurrTripType?.[theKey])
          );
        });
      });
    },
    [getFieldsByCurrTripType, serviceProviderId, luggageAmount, passengersAmount],
  );

  const getQuoteCall = useCallback(
    async (vehicleClassToQuoteId: string): Promise<ServiceProviderVehicleClassType | null> =>
    {
      const locations = [{ ...pickupAddress }];
      if (tripType === TripTypes.OneWay || tripType === TripTypes.RoundTrip) {
        locations.push(dropoffAddress);
      }

      const requestBody: RequestVehicleClassType = {
        TripType: tripType,
        Locations: locations,
        NumberOfPassengers: parseInt(passengersAmount),
        AmountLuggage: parseInt(luggageAmount),
        PickUpDateTime: `${pickupDate}T${pickupTime}:00`,
        BusinessProgramId: programPass?.businessProgram.id,
      };

      if (tripType === TripTypes.RoundTrip) {
        requestBody.ReturnDateTime = `${returnPickupDate}T${returnPickupTime}:00`;
      }

      if (tripType === TripTypes.Hourly) {
        requestBody.EstimatedTimeInHours = parseInt(estimatedTimeInHours);
      }

      return await post<ServiceProviderVehicleClassType, RequestVehicleClassType>(
        `${ENDPOINTS.GetQuote(vehicleClassToQuoteId)}`,
        false,
        requestBody,
        true,
        true,
      );
    },
    [
      dropoffAddress,
      estimatedTimeInHours,
      luggageAmount,
      passengersAmount,
      pickupAddress,
      pickupDate,
      pickupTime,
      post,
      programPass?.businessProgram.id,
      returnPickupDate,
      returnPickupTime,
      tripType,
    ],
  );

  const getVehicleClassOnDemand = async (vehicleClassToQuoteId: string): Promise<void> =>
  {
    setOnDemandLoading(true);
    const foundIndex = vehicleClassesToQuote?.findIndex(
      (vCTQ) => vCTQ.serviceProviderVehicleClassId === vehicleClassToQuoteId,
    );

    setVehicleClassesToQuote((prevState) =>
    {
      const newState = [...(prevState ?? [])];
      if (foundIndex !== null && foundIndex !== undefined && foundIndex >= 0) {
        newState[foundIndex].failedToLoad = false;
      }

      return [...newState];
    });
    const foundVehicleClass = await getQuoteCall(vehicleClassToQuoteId);

    if (foundVehicleClass) {
      setVehicleClassesPool((prevState) =>
      {
        const newState = [...(prevState ?? [])];

        newState.push({
          ...getFieldsByCurrTripType(),
          serviceProviderId: serviceProviderId ?? '',
          luggageAmount,
          passengersAmount,
          data: foundVehicleClass,
        } as VehicleClassesPoolType);

        return newState;
      });
      setVehicleClasses((prevState) =>
      {
        const newState = [...(prevState ?? [])];
        if (foundIndex !== null && foundIndex !== undefined && foundIndex >= 0) {
          if (foundVehicleClass) {
            newState[foundIndex] = {
              ...foundVehicleClass,
            } as ServiceProviderVehicleClassType;
          }
        }

        return newState;
      });
    }

    setVehicleClassesToQuote((prevState) =>
    {
      const newState = [...(prevState ?? [])];
      if (foundIndex !== null && foundIndex !== undefined && foundIndex >= 0) {
        newState[foundIndex].failedToLoad = true;
      }

      return [...newState];
    });
    setOnDemandLoading(false);
  };

  //THE BIG GUARDIAN
  const isStep1DataValid = useMemo(() =>
  {
    const returned = getFieldsByCurrTripType();
    const isFormDataValid = returned
      ? Object.values(returned).every((value) => value !== undefined)
      : false;

    if (!isFormDataValid) {
      const passId = match?.params.code;
      if (passId) window.location.href = `/pass/${passId}/step1`;
      else window.location.href = '/book-ride/step1';
      return false;
    }

    return true;
  }, [match?.params.code, getFieldsByCurrTripType]);

  const showVehiclesPrices =
    !programPass || programPass.businessProgram.passCoverageType != PassCoverageTypes.FullAmount;

  //POOL MANAGER: VEHICLECLASSESTOQUOTE: IN CHARGE GETTING NONEXISTENT VEHICLECLASSESTOQUOTE AND ADD THEM TO THE POOL
  useEffect(() =>
  {
    if (isStep1DataValid) {
      // setIsGettingServiceProvider(true);

      const functionOne = async (): Promise<void> =>
      {
        setIsGettingServiceProvider(true);

        const requestBody: GetServiceProviderAndVehicles = {
          Latitude: pickupAddress?.latitude,
          Longitude: pickupAddress?.longitude,
          BusinessProgramId: programPass?.businessProgram.id,
          NumberOfPassengers: passengersAmount,
          AmountOfLuggage: luggageAmount,
          PickupTime: `${pickupDate}T${pickupTime}:00`,
          Hours: (tripType === TripTypes.Hourly ? parseInt(estimatedTimeInHours) : 0)
        };

        const endpoint = `${ENDPOINTS.GetServiceProviderAndVehicles}?${buildQueryString(requestBody)}`;

        if (pendingRequests.has(endpoint)) return;

        try {
          pendingRequests.add(endpoint);
          const response = await get<ServiceProviderWithVehicleClasses>(
            endpoint,
            false,
            false,
            true,
          );
          pendingRequests.delete(endpoint);

          setVehicleClassesToQuotePool((prevState) =>
          {
            const newState = [...(prevState ?? [])];
            newState.push({
              ...getFieldsByCurrTripType(),
              serviceProviderId: response.id,
              luggageAmount,
              passengersAmount,
              data: response.serviceProviderVehicleClasses,
            } as VehicleClassesToQuotePoolType);

            return newState;
          });
          setLastGooglePlacesIdPickup(pickupAddress?.googlePlacesId);
          setServiceProviderId(response.id);
        } catch (error) {
          console.error('Failed to load service provider and vehicles', error);
          trigger('PICKUP_ADDRESS');
        }
        setIsGettingServiceProvider(false);
      };

      const functionTwo = async (): Promise<void> =>
      {
        setIsGettingServiceProvider(true);

        const existInPool = isCurrCombinationInPool(vehicleClassesToQuotePool ?? []);
        const endpointString = CreateEndpointWithQueryParamsForRequestingVehicleClasses({
          passengersAmount,
          luggageAmount,
          programId: programPass?.businessProgram.id,
          serviceProviderId: serviceProviderId as string,
          pickupDate,
          pickupTime,
          estimatedTimeInHours: (tripType === TripTypes.Hourly ? parseInt(estimatedTimeInHours) : 0)
        });

        if (!existInPool && serviceProviderId && !pendingRequests.has(endpointString)) {
          pendingRequests.add(endpointString);
          const newVehicleCombination = await get<VehicleClassTypeResponse>(
            endpointString,
            false,
            false,
            true,
          );
          pendingRequests.delete(endpointString);

          setVehicleClassesToQuotePool((prevState) =>
          {
            const newState = [...(prevState ?? [])];
            newState.push({
              ...getFieldsByCurrTripType(),
              serviceProviderId: serviceProviderId,
              luggageAmount,
              passengersAmount,
              data: newVehicleCombination,
            } as VehicleClassesToQuotePoolType);

            return newState;
          });
        }
        setIsGettingServiceProvider(false);
      };

      if (
        pickupAddress?.googlePlacesId !== lastGooglePlacesIdPickup &&
        pickupAddress?.latitude &&
        pickupAddress?.longitude
      ) {
        functionOne();
      } else {
        const existInPool = isCurrCombinationInPool(vehicleClassesToQuotePool ?? []);

        const endpointString = CreateEndpointWithQueryParamsForRequestingVehicleClasses({
          passengersAmount,
          luggageAmount,
          programId: programPass?.businessProgram.id,
          serviceProviderId: serviceProviderId as string,
          pickupDate,
          pickupTime,
          estimatedTimeInHours: (tripType === TripTypes.Hourly ? parseInt(estimatedTimeInHours) : 0)
        });

        if (!existInPool && serviceProviderId && !pendingRequests.has(endpointString)) {
          functionTwo();
        }
      }
    }
  }, [
    get,
    getFieldsByCurrTripType,
    isCurrCombinationInPool,
    isStep1DataValid,
    lastGooglePlacesIdPickup,
    luggageAmount,
    passengersAmount,
    pickupAddress?.googlePlacesId,
    pickupAddress?.latitude,
    pickupAddress?.longitude,
    programPass?.businessProgram.id,
    serviceProviderId,
    setLastGooglePlacesIdPickup,
    setServiceProviderId,
    setVehicleClassesToQuotePool,
    trigger,
    vehicleClassesToQuotePool,
    pickupDate,
    pickupTime,
    tripType,
    estimatedTimeInHours,
  ]);

  //SLAVE:VEHICLECLASSESTOQUOTE: IN CHARGE OF SETTING THE USABLE VEHICLECLASSESTOQUOTE FROM POOL
  useEffect(() =>
  {
    const getNewVehiclesFromProviderId = async (): Promise<void> =>
    {
      if (vehicleClassesToQuotePool && vehicleClassesToQuotePool?.length > 0) {
        setVehicleClassesToQuote((prevState) =>
        {
          let newState = [...(prevState ?? [])];
          newState = newState.map((nS) =>
          {
            nS.failedToLoad = false;
            return nS;
          });

          return newState;
        });
        const foundInPool = isCurrCombinationInPool(vehicleClassesToQuotePool);

        if (foundInPool) {
          setVehicleClassesToQuote((foundInPool as VehicleClassesToQuotePoolType).data);
          setCanRetrieveQuotes(true);
        }
      }
    };
    getNewVehiclesFromProviderId();
  }, [
    get,
    passengersAmount,
    luggageAmount,
    serviceProviderId,
    setVehicleClassesToQuote,
    vehicleClassesToQuotePool,
    isCurrCombinationInPool,
  ]);

  //POOL MANAGER: VEHICLECLASSES :OLD GETVEHICLECLASSES(), NOW IT REACTS BY IT OWNS. CHECKS IF ANY QUOTE IS NOT IN THE POOL, RETRIEVE IT AND THEN ADD THEMS TO THE POOL
  useEffect(() =>
  {
    setIsBackendError(false);

    if (canRetrieveQuotes && showVehiclesPrices) {
      setCanRetrieveQuotes(false);
      try {
        const toCall = async (): Promise<void> =>
        {
          const allPossibleCombinations = vehicleClassesToQuotePool?.filter((vCTQP) =>
          {
            return vCTQP.data.every((dataVCTQP) =>
            {
              return vehicleClassesToQuote?.some(
                (vCTQ) =>
                  vCTQ.serviceProviderVehicleClassId === dataVCTQP.serviceProviderVehicleClassId,
              );
            });
          });

          const finalCombination = isCurrCombinationInPool(allPossibleCombinations ?? []);

          const toIterate = (finalCombination as VehicleClassesToQuotePoolType)?.data;

          const vehicleClassesPoolCombinationTotTestAgainst = isCurrCombinationInPool2(
            vehicleClassesPool as VehicleClassesBasePoolType[],
          );
          let finalToFind;
          if (
            vehicleClassesPoolCombinationTotTestAgainst &&
            vehicleClassesPoolCombinationTotTestAgainst?.length > 0
          ) {
            finalToFind = toIterate?.filter((tI) =>
            {
              return !(
                vehicleClassesPoolCombinationTotTestAgainst as VehicleClassesPoolType[]
              )?.some(
                (vCP) =>
                  vCP.data.serviceProviderVehicleClassId === tI.serviceProviderVehicleClassId,
              );
            });
          } else {
            finalToFind = toIterate;
          }

          const endpointCalls = finalToFind?.map((fO) =>
          {
            let numberOfTries = 1;

            const theCall = async (): Promise<void> =>
            {
              return getQuoteCall(fO?.serviceProviderVehicleClassId)
                .then((response) =>
                {
                  if (response) {
                    setVehicleClassesPool((prevState) =>
                    {
                      const newState = [...(prevState ?? [])];
                      newState.push({
                        ...getFieldsByCurrTripType(),
                        serviceProviderId: serviceProviderId ?? '',
                        luggageAmount,
                        passengersAmount,
                        data: response,
                      } as VehicleClassesPoolType);
                      return newState;
                    });
                  } else {
                    onFailure();
                  }
                })
                .catch((e) =>
                {
                  if (e.message !== 'canceled') {
                    onFailure();
                  }
                });
            };
            const onFailure = (): void =>
            {
              let delayTime = 0;
              if (numberOfTries === 1) {
                delayTime = 2000;
                // delayTime = 100;
              } else if (numberOfTries === 2) {
                delayTime = 5000;
                // delayTime = 100;
              } else if (numberOfTries === 3) {
                delayTime = 12000;
                // delayTime = 100;
              }
              if (numberOfTries <= 3) {
                const timeout = setTimeout(() => theCall(), delayTime);
                pageTimeOuts.push(timeout);
              } else {
                setVehicleClassesToQuote((prevState) =>
                {
                  const newState = [...(prevState ?? [])];
                  const foundIndex = newState.findIndex(
                    (nS) => nS.serviceProviderVehicleClassId === fO.serviceProviderVehicleClassId,
                  );
                  newState[foundIndex].failedToLoad = true;

                  return newState;
                });
              }
              numberOfTries++;
            };
            return theCall;
          });

          for (const endpointCall of endpointCalls ?? []) {
            endpointCall();

            await new Promise((resolve) =>
            {
              const timeout = setTimeout(resolve, 1000);
              pageTimeOuts.push(timeout);
            });
          }
        };

        toCall();
      } catch (e) {
        setIsBackendError(true);
      }
    }
  }, [
    canRetrieveQuotes,
    getQuoteCall,
    luggageAmount,
    passengersAmount,
    vehicleClassesToQuote,
    setVehicleClassesToQuote,
    vehicleClassesPool,
    setVehicleClassesPool,
    getFieldsByCurrTripType,
    serviceProviderId,
    isCurrCombinationInPool,
    vehicleClassesToQuotePool,
    isCurrCombinationInPool2,
    showVehiclesPrices,
  ]);

  //SLAVE: VEHICLECLASSES :IN CHARGE OF INSERTING QUOTES ALREADY IN THE POOL IN THE USABLE VEHICLECLASSES STATE
  useEffect(() =>
  {
    if (vehicleClassesPool && vehicleClassesPool?.length > 0) {
      vehicleClassesToQuote?.forEach((vehicleClass) =>
      {
        const foundInPools = isCurrCombinationInPool2(vehicleClassesPool);
        const foundInPool = (foundInPools as VehicleClassesPoolType[])?.find(
          (fIP) =>
            fIP.data.serviceProviderVehicleClassId !== vehicleClass.serviceProviderVehicleClassId,
        );

        if (foundInPool) {
          setVehicleClasses((prevState) =>
          {
            const newState = [...(prevState ?? [])];
            newState.push(foundInPool.data);
            return newState;
          });
        }
      });
    }
  }, [
    luggageAmount,
    passengersAmount,
    vehicleClassesPool,
    vehicleClassesToQuote,
    isCurrCombinationInPool2,
  ]);

  useEffect(() =>
  {
    return () =>
    {
      abortQueries(ENDPOINTS.GetQuote(''));
    };
  }, [abortQueries, passengersAmount, luggageAmount]);

  useEffect(() =>
  {
    const clearTimeOuts = (): void =>
    {
      pageTimeOuts.forEach((timeoutId) =>
      {
        clearTimeout(timeoutId);
      });

      pageTimeOuts = [];
    };
    clearTimeOuts();

    return () =>
    {
      clearTimeOuts();
    };
  }, [passengersAmount, luggageAmount]);

  const vehicleClassesElements = vehicleClassesToQuote?.map((sL): ReactElement =>
  {
    const oldAval = sL.isAvailable; // Keep in mem as the below keeps unmapping the bool

    const foundInPools = isCurrCombinationInPool2(vehicleClassesPool ?? []);
    const foundVehicle = (foundInPools as VehicleClassesPoolType[])?.find(
      (fIP) => fIP.data.serviceProviderVehicleClassId === sL.serviceProviderVehicleClassId,
    );
    if (foundVehicle?.data) {
      (sL as unknown as ServiceProviderVehicleClassType) = foundVehicle.data;
    }

    sL.isAvailable = oldAval;

    return (
      <Box key={sL.serviceProviderVehicleClassId}>
        <VehicleClassItem
          key={sL.serviceProviderVehicleClassId}
          title={sL.name}
          imageUrl={sL.image}
          callForAvailability={(sL.isAvailable === false)}
          showPrices={showVehiclesPrices}
          subtitle={sL.description}
          passengersAmount={sL.passengers}
          luggageAmount={sL.bags}
          onSelectVehicle={(): void =>
          {
            setValue(BookRideFieldNames.SERVICE_PROVIDER_VEHICLE_CLASS, sL);
            setValue(BookRideFieldNames.QUOTE_ID, sL.id);
            if (sL.roundtripLeg2) setValue(BookRideFieldNames.QUOTE_ID_LEG2, sL.roundtripLeg2.id);

            const step2PassUrl = `/pass/${programPass?.id}/step3` + location.search;
            const step2NormalUrl = `/book-ride/step3` + location.search;

            if (programPass) {
              navigate(step2PassUrl);
            } else {
              navigate(step2NormalUrl);
            }
          }}
          subTotalGeneral={sL.subTotalGeneral}
          totalGeneral={sL.totalGeneral}
          services={sL.services}
          leg1Data={[
            {
              label: 'Estimated total',
              value: sL.total,
              isTitle: true,
              valueDataCy: 'totalLeg1',
            },
            ...(sL.surchargesList?.map((surcharge: SurchargesList) =>
            {
              return {
                label: surcharge.description,
                value: surcharge.amount,
              };
            }) || []),
            {
              label: 'Base fare',
              value: sL.subTotal,
            },
            { label: 'Estimated taxes', value: sL.tax },
            { label: 'Tip', value: sL.tip, isVisible: sL.tip > 0 },
          ]}
          leg2Data={[
            {
              label: 'Estimated total',
              value: sL.roundtripLeg2?.total ?? 0,
              isTitle: true,
              valueDataCy: 'totalLeg1',
            },
            ...(sL.roundtripLeg2?.surchargesList?.map((surcharge: SurchargesList) =>
            {
              return {
                label: surcharge.description,
                value: surcharge.amount,
              };
            }) ?? []),
            {
              label: 'Base fare',
              value: sL.roundtripLeg2?.subTotal ?? 0,
            },
            {
              label: 'Estimated taxes',
              value: sL.roundtripLeg2?.tax ?? 0,
            },
            {
              label: 'Tip',
              value: sL.roundtripLeg2?.tip ?? 0,
              isVisible: (sL.roundtripLeg2?.tip ?? 0) > 0,
            },
          ]}
          failedToLoad={sL?.failedToLoad}
          getVehicleClassOnDemand={(): void =>
          {
            getVehicleClassOnDemand(sL.serviceProviderVehicleClassId);
          }}
        />
      </Box>
    );
  });

  if (isGettingServiceProvider) {
    return <>{createPortal(<FullScreenLoader />, document.body)}</>;
  }

  return (
    <>
      {onDemandLoading ||
        vehicleClassesToQuote?.every((vCtQ) => vCtQ.failedToLoad === true) ||
        !showVehiclesPrices ||
        (!isCurrCombinationInPool2(vehicleClassesPool as VehicleClassesBasePoolType[])?.some(
          (combination) =>
          {
            return vehicleClassesToQuote?.some(
              (vC) =>
                vC.serviceProviderVehicleClassId ===
                (combination as VehicleClassesPoolType).data.serviceProviderVehicleClassId,
            );
          },
        ) &&
          createPortal(<FullScreenLoader />, document.body))}

      <Stack>
        <Typography
          sx={{
            ...TYPOGRAPHY.title1,
          }}
        >
          Select your ride
        </Typography>
        <Typography
          sx={{
            ...TYPOGRAPHY.paragraph,
          }}
        >
          Find a vehicle that fits you
        </Typography>

        <Stack direction="row" spacing="10px" mt="28px">
          <RHFSelect
            name={BookRideFieldNames.PASSENGERS_AMOUNT}
            title="Number of Passengers"
            sx={getSelectFieldStyles('icon_user.svg')}
          >
            {[...Array(11)].map((_, index) => (
              <MenuItem key={index + 1} value={String(index + 1)}>
                {index + 1}
              </MenuItem>
            ))}
          </RHFSelect>

          <RHFSelect
            name={BookRideFieldNames.LUGGAGE_AMOUNT}
            title="Number of Bags / Luggage"
            sx={getSelectFieldStyles('icon_luggage.svg')}
          >
            {[...Array(12)].map((_, index) => (
              <MenuItem key={index} value={String(index)}>
                {index}
              </MenuItem>
            ))}
          </RHFSelect>
        </Stack>

        <>
          {(vehicleClassesToQuote?.length ?? 0) > 0 && (
            <Typography
              sx={{
                ...TYPOGRAPHY.paragraph,
                mt: '10px',
              }}
              data-cy="vehicleClassesCount"
            >
              Showing {vehicleClassesToQuote?.length} results
            </Typography>
          )}
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                zIndex: 1,
                gap: '10px',
                display: 'grid',
                gridTemplateColumns: {
                  xs: '1fr',
                  sm: 'repeat(3, 1fr)',
                },
              }}
            >
              {vehicleClassesElements}
            </Box>
          </Box>
          {isBackendError && vehicleClasses?.length === 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <SvgIcon
                src="/assets/icons/icon_outlinedRoundedX.svg"
                width="60px"
                height="60px"
                color="black"
              />
              <Typography
                sx={{
                  mt: '13px',
                  ...TYPOGRAPHY.title2,
                }}
              >
                Uh oh
              </Typography>
              <Typography
                sx={{
                  mt: '13px',
                  textAlign: 'center',
                  ...TYPOGRAPHY.paragraph,
                }}
                data-cy="backendError"
              >
                There seems to be a temporary problem with your request. Please try again
              </Typography>

              <Stack spacing="10px" mt="25px">
                <AppButton title="Try again" />
                <AppButton
                  title="Start over"
                  variant="outlined"
                  onClick={(): void =>
                  {
                    const programPassId = programPass?.id;
                    if (programPassId) navigate(`/pass/${programPassId}/step1${location.search}`);
                    else navigate(`/book-ride/step1${location.search}`);
                  }}
                />
              </Stack>
            </Box>
          )}

          {!isBackendError && vehicleClassesToQuote?.length === 0 && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <img src="/assets/icons/icon_seachXOnFiles.svg" alt="icon_seachXOnFiles" />
              <Typography
                sx={{
                  mt: '10px',
                  ...TYPOGRAPHY.title4,
                }}
                data-cy="noResults"
              >
                Nothing found
              </Typography>
              <Box>
                <AppButton
                  title="Go back"
                  variant="outlined"
                  onClick={(): void =>
                  {
                    const programPassId = programPass?.id;
                    if (programPassId) navigate(`/pass/${programPassId}/step1${location.search}`);
                    else navigate(`/book-ride/step1${location.search}`);
                  }}
                  additionalSx={{
                    mt: '20px',
                  }}
                />
              </Box>
            </Box>
          )}
        </>
      </Stack>
    </>
  );
};

export default VehicleClassStep2;
