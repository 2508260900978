import { Stack, Switch, FormControlLabel, Typography } from '@mui/material';
import { TYPOGRAPHY } from '../../../../theme/typography';
import { BookRideFieldNames } from '../../types';
import { FC, ReactElement } from 'react';
import CardStepItem from './CardStepItem';
import { useFormContext } from 'react-hook-form';

interface Props
{
  onContinue: () => void;
  onEdit: () => void;
  isEditing: boolean;
  displayEditButton: boolean;
  showPaymentSection: boolean;
  requireFlightInfo: boolean | string | undefined;
}
const AddToTripInfo: FC<Props> = ({
  onContinue,
  onEdit,
  isEditing = true,
  displayEditButton,
  showPaymentSection,
  requireFlightInfo,
}): ReactElement =>
{

  const { watch, setValue, getValues } = useFormContext();

  const [tripProtectionFee, luggageAssistFee] = watch([BookRideFieldNames.TRIP_PROTECTION, BookRideFieldNames.FLIGHT_LUGGAGE_ASSIST]);

  return (
    <CardStepItem
      title={`Add to your trip`}
      onContinue={onContinue}
      onEdit={onEdit}
      isEditing={isEditing}
      displayEditButton={displayEditButton}
      containerDataCy="addToTrip-card"
      titleDataCy="addToTrip-card-text"
      buttonDataCy="addToTrip-button"
      editButtonDataCy="addToTrip-details-edit-btn"
    >
      {!isEditing && (
        <>
          <Typography
            sx={{
              ...TYPOGRAPHY.paragraph,
              mt: '5px!important',
            }}
            display={tripProtectionFee > 0 ? undefined : 'none'}
          >
            Trip protection: Added
          </Typography>

          <Typography
            sx={{
              ...TYPOGRAPHY.paragraph,
              mt: '5px!important',
            }}
            display={luggageAssistFee >= 0 ? undefined : 'none'}
          >
            Luggage assistance: Added
          </Typography>
        </>
      )}
      {isEditing && (
        <Stack spacing="15px" direction="column">

          {showPaymentSection && (
            <>

              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <FormControlLabel
                  control={
                    <Switch
                      name={BookRideFieldNames.TRIP_PROTECTION}
                      color="primary"
                      checked={tripProtectionFee > 0}
                      onChange={(e, isChecked): void =>
                      {
                        setValue(BookRideFieldNames.TRIP_PROTECTION, (isChecked ? 9 : undefined));
                      }}
                    />
                  }
                  label={"Trip protection"}
                />

                <Typography
                  align='right'
                  sx={{
                    ...TYPOGRAPHY.paragraph,
                    mt: '5px',
                  }}
                >
                  +$9
                </Typography>
              </Stack>

              <Typography
                sx={{
                  ...TYPOGRAPHY.tinyText,
                  mt: '5px',
                  opacity: 0.7,
                }}
              >
                For just $9, you can add trip protection, which is a non-refundable fee allowing cancellation 1 hour before pick-up time on all reservations.
              </Typography>

            </>
          )}

          {requireFlightInfo && (
            <>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <FormControlLabel
                  control={
                    <Switch
                      name="{BookRideFieldNames.FLIGHT_LUGGAGE_ASSIST}"
                      color="primary"
                      checked={luggageAssistFee >= 0}
                      onChange={(e, isChecked): void =>
                      {
                        setValue(BookRideFieldNames.FLIGHT_LUGGAGE_ASSIST, (isChecked ? 0 : undefined));
                      }}
                    />
                  }
                  label={"Luggage assistance"}
                />
                <Typography
                  align='right'
                  sx={{
                    ...TYPOGRAPHY.paragraph,
                    mt: '5px',
                  }}
                >

                </Typography>
              </Stack>
              <Typography
                sx={{
                  ...TYPOGRAPHY.tinyText,
                  mt: '5px',
                  opacity: 0.7,
                }}
              >
                With this option, your chauffeur will meet you at baggage claim with a name sign to assist with luggage and escort you and your guests directly to the vehicle that you reserved.
              </Typography>
            </>
          )}


        </Stack>
      )}
    </CardStepItem>
  );
};

export default AddToTripInfo;
