import { Box, SxProps, useMediaQuery } from '@mui/material';
import AppButton from './AppButton';
import { FC, ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { COLORS } from '../../theme/pallete';
import { useAuthContext } from 'auth/useAuthContext';
import { LOGO_ALT, LOGO_IMAGE } from 'shared.config';
import LoggedInUserMenu from './LoggedInUserMenu';
import { TripSummary } from './TripSummary';
import { useProgramPassContext } from 'pages/ProgramPassContext';

const AppTopBar: FC<{ sx?: SxProps }> = ({
  sx,
}): ReactElement => {
  const isMobile = useMediaQuery('(max-width:982px)');
  const { login, user } = useAuthContext();
  const location = useLocation();
  const programPass = useProgramPassContext();

  return (
    <Box
      sx={{
        display: 'flex',
        borderBottom: `1px solid ${COLORS.GREY_LIGHT_1}`,
        position: 'fixed',
        width: '100%',
        backgroundColor: 'white',
        minHeight: '80px',
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          ...sx,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '5px',
          flexWrap: 'wrap',
          my: '10px',
        }}
      >
        <Link
          to={`${programPass.programPass ? `/pass/${programPass.programPass?.id}/step1` : `/book-ride/step1${location.search}`}`}
          data-cy="kaptyn-logo"
        >
          <img src={LOGO_IMAGE} alt={LOGO_ALT} style={{ height: '40px' }} />
        </Link>

        {location.pathname.includes('/step2') && <TripSummary />}

        {!user && (
          <AppButton
            title={isMobile ? '' : 'Sign in | Create account'}
            variant="outlined"
            startIcon={
              <img
                src="/assets/icons/icon_userProfile.svg"
                alt="user profile"
              />
            }
            additionalSx={{
              display: 'inline-flex',
              width: 'auto',
              '.MuiButton-icon': {
                margin: '0',
              },
              '.buttonText': {
                ml: '8px',
              },
            }}
            onClick={login}
            data-cy="sign-in-button"
          />
        )}
        {user && <LoggedInUserMenu isPass={Boolean(programPass.programPass)} />}
      </Box>
    </Box>
  );
};

export default AppTopBar;
