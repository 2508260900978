import { SUPPORT_NUMBER } from 'shared.config';

export const AppFormErrorMgs = {
  required: 'This field is required',
  number: 'This field must contain only numbers',
  email: 'This field must be a valid email',
  emailExist: 'This email already exist',
  equalToPickup: 'This field cannot be equal to pickup',
  equalToDropOff: 'This field cannot be equal to dropoff',
  locationOutOfService: `This location is outside of our normal service area, however, we can fulfill your request. Please call our live concierge at ${SUPPORT_NUMBER} for assistance creating this reservation.`,
  timeInFuture: (afterCriteria: string): string =>
    `Time must be after ${afterCriteria}`,
  atLeastAmountChars: (charLength: number): string =>
    `It must have at least ${charLength} characters`,
  atLeastAmountUpperChar: (charLength: number): string =>
    `This field must have at least ${charLength} uppercase character`,
  orgAlreadyNameUsed: 'Account name is already used',
  invalidPhoneNumber: 'Invalid phone number',
  emailsInvalid: 'The list of emails are not valid',
  imageTooLarge: 'Invalid image dimensions. It must be 1200 x 400 px',
  imageTooBig: 'File Size is too large. It must be under 2 mb',
  returnPickTimeAfter: 'The return pickup time must be after the pickup time',
  cannotStartWithZero: 'Cannot start with 0',
  wrongAddress: 'The address is invalid. Please select a different one',
};
