import { LatLng } from '../../../pages/bookRide/types';
import React, { ReactElement } from 'react';
import { FormControl, Typography } from '@mui/material';
import { TYPOGRAPHY } from '../../../theme/typography';
import { Controller, useFormContext } from 'react-hook-form';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { COLORS } from '../../../theme/pallete';
import { AutocompletionRequest } from 'react-google-places-autocomplete/build/types';
import SvgIcon from '../SvgIcon';
import { AddressTypes } from '../../../shared.types';
import {
  getLocationFromGooglePlacesId,
} from '../../../utils/googlePlacesUtils';

const autocompletionRequest: AutocompletionRequest = {
  types: ['geocode', 'establishment'],
  componentRestrictions: { country: ['us'] },
};

const excludeTypes = ['political', 'route'];

const CustomValueContainer = ({
  children,
}: {
  children: React.ReactNode;
}): ReactElement => (
  <div
    style={{
      padding: '2px 8px',
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      position: 'relative',
      boxSizing: 'border-box',
    }}
  >
    <SvgIcon src="/assets/icons/icon_magnifier.svg " width="18px" />
    <div
      style={{
        marginLeft: 10.5,
        alignItems: 'center',
        display: 'grid',
        width: '100%',
      }}
    >
      {children}
    </div>
  </div>
);

export const PlacesAutocomplete = ({
  name,
  placeholder,
  locationBias,
  addressType = 0,
  dataCy,
}: {
  name: string;
  placeholder?: string;
  locationBias?: LatLng;
  addressType?: AddressTypes;
  dataCy?: string;
}): ReactElement => {
  const { control } = useFormContext();

  return (
    <FormControl
      data-cy={dataCy}
      sx={{
        ...TYPOGRAPHY.formField,
        width: '100%',
        '[class*=IndicatorsContainer]': {
          display: 'none!important',
        },

        '.svg-color': {
          flexShrink: 0,
        },

        '@supports (-moz-appearance: none)': {
          '& > div > div > div, & > div > div > div > div,': {
            width: '100%',
          },
          '& > div > div > div > div > div *:not(input):last-child': {
            display: 'none',
          },
          '.css-1dimb5e-singleValue': {
            '@media only screen and (max-width:2000px)': {
              maxWidth: '97%',
            },
            '@media only screen and (max-width:1725px)': {
              maxWidth: '95%',
            },
            '@media only screen and (max-width:1584px)': {
              maxWidth: '93%',
            },
            '@media only screen and (max-width:1199px)': {
              maxWidth: '95%',
            },
            '@media only screen and (max-width: 895px)': {
              maxWidth: '99%',
            },
            '@media only screen and (max-width: 713px)': {
              maxWidth: '95%',
            },
            '@media only screen and (max-width: 614px)': {
              maxWidth: '93%',
            },
            '@media only screen and (max-width: 463px )': {
              maxWidth: '90%',
            },
          },
        },
        '.css-1avbrlz-control': {
          borderRadius: '10px',
        },
        '*[class*="css"][class*="control"]': {
          overflow: 'hidden',
        },
      }}
    >
      <Controller
        name={name}
        control={control}
        render={({
          field: { value, onChange },
          // fieldState: { error },
        }): ReactElement => {
          return (
            <GooglePlacesAutocomplete
              autocompletionRequest={{
                ...autocompletionRequest,
                locationBias,
                locationBiasRadius: 50000,
              }}
              minLengthAutocomplete={2}
              selectProps={{
                value: value
                  ? {
                    label: value?.address,
                    value: {
                      description: value?.address,
                    },
                  }
                  : null,
                placeholder: (
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: '#8F9299',
                    }}
                  >
                    {placeholder}
                  </Typography>
                ),
                styles: {
                  control: (baseStyles) => ({
                    ...baseStyles,
                    height: '48px',
                    backgroundColor: COLORS.GREY_MEDIUM_5,
                    border: 'none',
                    borderRadius: '10px',
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: COLORS.BLACK_DARK_2,
                    fontSize: '16px',
                    overflow: 'hidden', // Added to handle text overflow
                    textOverflow: 'ellipsis', // Text outside the content area is hidden
                    whiteSpace: 'nowrap', // Text will never wrap, it will continue on the same line
                  }),
                },
                components: {
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  ValueContainer: CustomValueContainer,
                },
                blurInputOnSelect: true,
                onChange: async (e): Promise<void> => {
                  const location = await getLocationFromGooglePlacesId(e?.value.place_id, e?.value.description, addressType);
                  onChange(location);
                },
              }}
              suggestionsFilter={(
                suggestions,
              ): google.maps.places.AutocompletePrediction[] =>
                suggestions.filter(
                  (sug) =>
                    !sug.types.some((type) => excludeTypes.includes(type)),
                )
              }
            />
          );
        }}
      />
    </FormControl>
  );
};

export default PlacesAutocomplete;
