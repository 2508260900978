import { Box, Stack, Typography } from '@mui/material';
import { FONT_FAMILY, TYPOGRAPHY } from '../../../../theme/typography';
import { ReactElement, useMemo } from 'react';
import useModal from '../../../../HookComponents/useModal';
import AppButton from '../../../../components/shared/AppButton';
import { useFormContext } from 'react-hook-form';
import { BookRideFieldNames, TripTypes } from '../../types';
import { formatToUSD } from '../../../../utils/MoneyConvertion';
import CostBreakdown from '../../../../components/CostBreakdown';
import { SurchargeNames, SurchargesList as SurchargeType } from '../../../../shared.types';

const Total = ({ showPrices = true }: { showPrices: boolean }): ReactElement =>
{
  const { Modal, open, openModal, closeModal } = useModal();

  const { watch } = useFormContext();

  const [tripType, selectedVehicle, extraGratuity, businessTravelProgram, tripProtectionFee, luggageAssistFee] = watch([
    BookRideFieldNames.TRIP_TYPE,
    BookRideFieldNames.SERVICE_PROVIDER_VEHICLE_CLASS,
    BookRideFieldNames.EXTRA_GRATUITY,
    BookRideFieldNames.BUSINESS_TRAVEL_PROGRAM,
    BookRideFieldNames.TRIP_PROTECTION,
    BookRideFieldNames.FLIGHT_LUGGAGE_ASSIST,
  ]);

  const isAutoGratuityPresentInTheQuote = selectedVehicle.surchargesList?.find(
    (surcharge: SurchargeType) => surcharge.description === SurchargeNames.AutoGratuity
  );

  const autoGratuityPercentageToAdd = isAutoGratuityPresentInTheQuote ? 0 : businessTravelProgram?.autoGratuityPercentage || 0;

  const getPercentage = (baseNumber: number, percentageAmount: number): number =>
  {
    return (baseNumber * percentageAmount) / 100;
  };

  const totalLeg1 = useMemo(() =>
  {
    if (showPrices) {
      if (
        typeof selectedVehicle?.total === 'number' &&
        typeof selectedVehicle?.subTotal === 'number'
      ) {
        const totalGratuityPercentage =
          autoGratuityPercentageToAdd +
          parseInt(
            extraGratuity.percentage >= 0 && extraGratuity.percentage <= 100
              ? extraGratuity.percentage || 0
              : 0,
          );
        const calculatedExtraGratuity = totalGratuityPercentage
          ? getPercentage(selectedVehicle.subTotal, totalGratuityPercentage)
          : 0;

        return selectedVehicle.total + calculatedExtraGratuity + (tripProtectionFee ?? 0) + (luggageAssistFee ?? 0);
      }
    } else {
      return '* Included in package';
    }

    return 0;
  }, [autoGratuityPercentageToAdd, extraGratuity.percentage, selectedVehicle.subTotal, selectedVehicle.total, showPrices, tripProtectionFee, luggageAssistFee]);

  const totalLeg2 = useMemo(() =>
  {
    if (tripType !== TripTypes.RoundTrip)
      return '';

    if (showPrices) {
      if (
        typeof selectedVehicle?.roundtripLeg2?.total === 'number' &&
        typeof selectedVehicle?.roundtripLeg2?.subTotal === 'number'
      ) {
        const totalGratuityPercentage =
          autoGratuityPercentageToAdd +
          parseInt(
            extraGratuity.percentage >= 0 && extraGratuity.percentage <= 100
              ? extraGratuity.percentage || 0
              : 0,
          );
        const calculatedExtraGratuity = totalGratuityPercentage
          ? getPercentage(selectedVehicle?.roundtripLeg2?.subTotal, totalGratuityPercentage)
          : 0;

        return selectedVehicle?.roundtripLeg2?.total + calculatedExtraGratuity + (tripProtectionFee ?? 0) + (luggageAssistFee ?? 0);
      }
    } else {
      return '* Included in package';
    }

    return 0;
  }, [tripType, showPrices, selectedVehicle?.roundtripLeg2?.total, selectedVehicle?.roundtripLeg2?.subTotal, autoGratuityPercentageToAdd, extraGratuity.percentage, tripProtectionFee, luggageAssistFee]);

  const totalText = (): string =>
  {
    if (!showPrices) {
      return '* Included in package';
    } else {
      if (tripType === TripTypes.RoundTrip) {
        return formatToUSD(totalLeg1 + totalLeg2);
      }

      return formatToUSD(totalLeg1);
    }
  }

  return (
    <Box
      sx={{
        p: '20px',
      }}
      data-cy="total-card"
    >
      {showPrices && (
        <Modal
          isOpen={open}
          onClose={closeModal}
          title="Total breakdown"
          dialogStyles={{
            maxWidth: '444px',
            margin: '0 auto',
          }}
          dataCy="total-breakdown"
        >
          <CostBreakdown
            leg1Data={[
              {
                label: 'Total',
                value: totalLeg1,
                isTitle: true,
                valueDataCy: 'totalLeg1',
              },
              {
                label: selectedVehicle?.name,
                value: selectedVehicle.subTotal,
              },
              { label: 'Taxes and fees', value: '', isTitle: true },
              ...(selectedVehicle.surchargesList?.map((surcharge: SurchargeType) =>
              {
                return {
                  label: surcharge.description,
                  value: surcharge.amount,
                };
              }) || []),
              { label: 'Estimated taxes', value: selectedVehicle.tax },
              { label: 'Add ons', value: '', isTitle: true },
              {
                label: SurchargeNames.TripProtection,
                value: tripProtectionFee,
                isVisible: tripProtectionFee > 0,
              },
              {
                label: SurchargeNames.LuggageAssistance,
                value: luggageAssistFee,
                isVisible: luggageAssistFee >= 0,
              },
              ...(autoGratuityPercentageToAdd > 0 ? [{
                label: `Gratuity** (${businessTravelProgram?.autoGratuityPercentage}%)`,
                value: getPercentage(
                  selectedVehicle.subTotal,
                  businessTravelProgram?.autoGratuityPercentage,
                ),
                isVisible: Boolean(businessTravelProgram?.autoGratuityPercentage),
              }] : []),
              {
                label: `Additional gratuity ${extraGratuity.percentage}%`,
                value: getPercentage(selectedVehicle.subTotal, extraGratuity.percentage),
                isVisible: extraGratuity.percentage,
              },
            ]}
            leg2Data={[
              {
                label: 'Total',
                value: totalLeg2,
                isTitle: true,
                valueDataCy: 'totalLeg2',
              },
              {
                label: selectedVehicle?.name,
                value: selectedVehicle.roundtripLeg2?.subTotal ?? 0,
              },
              { label: 'Taxes and fees', value: '', isTitle: true },
              ...(selectedVehicle.roundtripLeg2?.surchargesList?.map(
                (surcharge: SurchargeType) =>
                {
                  return {
                    label: surcharge.description,
                    value: surcharge.amount,
                  };
                },
              ) ?? []),
              { label: 'Estimated taxes', value: selectedVehicle.roundtripLeg2?.tax ?? 0 },
              { label: 'Add ons', value: '', isTitle: true },
              {
                label: SurchargeNames.TripProtection,
                value: tripProtectionFee,
                isVisible: tripProtectionFee > 0,
              },
              {
                label: SurchargeNames.LuggageAssistance,
                value: luggageAssistFee,
                isVisible: luggageAssistFee >= 0,
              },
              ...(autoGratuityPercentageToAdd > 0 ?
                [{
                  label: `Gratuity** (${businessTravelProgram?.autoGratuityPercentage}%)`,
                  value: getPercentage(
                    selectedVehicle?.roundtripLeg2?.subTotal,
                    businessTravelProgram?.autoGratuityPercentage,
                  ),
                  isVisible: Boolean(businessTravelProgram?.autoGratuityPercentage),
                }] : []),
              {
                label: `Additional gratuity ${extraGratuity.percentage}%`,
                value: getPercentage(
                  selectedVehicle?.roundtripLeg2?.subTotal,
                  extraGratuity.percentage,
                ),
                isVisible: extraGratuity.percentage,
              },
            ]}
          />

          {businessTravelProgram?.autoGratuityPercentage && (
            <Typography
              sx={{
                fontSize: '12px',
                fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
                mt: '10px',
              }}
            >
              **A {businessTravelProgram?.autoGratuityPercentage}% automatic gratuity is applied to
              your ride and goes 100% to your driver as a thank you for their great service.
            </Typography>
          )}
          <AppButton
            title="Close"
            variant="outlined"
            additionalSx={{
              mt: '20px',
            }}
            onClick={closeModal}
            data-cy="close-button"
          />
        </Modal>
      )}

      <Stack direction="row" justifyContent="space-between">
        <Box
          sx={{
            ...TYPOGRAPHY.title4,
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          Total
          {showPrices && (
            <Box
              sx={{
                width: '15px',
                height: '15px',
                backgroundColor: '#464A4F',
                color: 'white',
                borderRadius: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '12px',
                fontFamily: FONT_FAMILY.CHROMATIC_PRO_REGULAR,
                ml: '10px',
                cursor: 'pointer',
              }}
              onClick={openModal}
              data-cy="total-info-btn"
            >
              i
            </Box>
          )}
        </Box>

        <Typography
          sx={{
            ...TYPOGRAPHY.title4,
          }}
          data-cy="total-cost"
        >
          {totalText()}
        </Typography>
      </Stack>

      <Typography
        sx={{
          ...TYPOGRAPHY.tinyText,
          mt: '20px',
        }}
      >
        By booking your reservation, you agree to Kaptyn&apos;s Terms of Use and consent to its
        Privacy Policy.
      </Typography>
    </Box>
  );
};

export default Total;
