import { Box, darken, lighten, Stack, Typography } from '@mui/material';
import { COLORS } from '../../../../theme/pallete';
import { TYPOGRAPHY } from '../../../../theme/typography';
import SvgIcon from '../../../../components/shared/SvgIcon';
import AppButton from '../../../../components/shared/AppButton';
import React, { ReactElement } from 'react';
import useModal from '../../../../HookComponents/useModal';
import { formatToUSD } from '../../../../utils/MoneyConvertion';
import CostBreakdown from '../../../../components/CostBreakdown';
import { LegData } from '../../../../shared.types';
import { PHONE_NUMBER_E164_FORMAT } from '../../../../shared.config';

const fillGlowingAnimation = {
  '@keyframes fill-glowing-animation': {
    '0%': {
      backgroundColor: lighten(COLORS.GREY_LIGHT_6, 0.3),
    },
    '100%': {
      backgroundColor: darken(COLORS.GREY_LIGHT_6, 0.07),
    },
  },
  animation: `fill-glowing-animation 1s both ease-in-out infinite alternate`,
};

export interface VehicleClassItemType
{
  title: string;
  imageUrl: string;
  callForAvailability: boolean;
  subtitle: string;
  passengersAmount?: number;
  luggageAmount?: number;
  onSelectVehicle: () => void;
  services: string;
  subTotalGeneral: number;
  totalGeneral: number;
  leg1Data: LegData[];
  leg2Data?: LegData[];
  showPrices: boolean;
  failedToLoad?: boolean;
  getVehicleClassOnDemand: () => void;
}

const VehicleClassItem = React.forwardRef(
  (
    {
      title,
      imageUrl,
      callForAvailability,
      subtitle,
      passengersAmount,
      luggageAmount,
      onSelectVehicle,
      services,
      subTotalGeneral,
      totalGeneral,
      leg1Data,
      leg2Data,
      showPrices = true,
      failedToLoad,
      getVehicleClassOnDemand,
    }: VehicleClassItemType,

    ref,
  ): ReactElement =>
  {
    const { Modal, open, closeModal, openModal } = useModal();

    return (
      <Box
        ref={ref}
        sx={{
          border: `1px solid ${COLORS.GREY_LIGHT_5}`,
          borderRadius: '10px',
          overflow: 'hidden',
          height: '100%',
          display: 'flex',
          backgroundColor: 'white',
          flexDirection: 'column',
          opacity: 0,
          '@keyframes on-load-animation': {
            '0%': {
              opacity: 0,
            },
            '100%': {
              opacity: 1,
            },
          },
          animation: `on-load-animation 0.2s ease-out both`,
        }}
        data-cy="serviceLevelListItemCard"
      >
        <Modal
          isOpen={open}
          onClose={closeModal}
          title="Fare estimate"
          dialogStyles={{
            margin: '0 auto',
            padding: '16px',

            '.MuiPaper-root': {
              width: '100%',
              maxWidth: '400px',
              margin: 0,
              borderRadius: '10px',
            },
          }}
          dataCy="fareEstimateModal"
        >
          <Typography
            sx={{
              ...TYPOGRAPHY.paragraph,
            }}
          >
            Your fare estimate reflects the expected time of your ride. Your final fare will be
            based on actual time driven.
          </Typography>
          <CostBreakdown leg1Data={leg1Data} leg2Data={leg2Data} />
          <Typography
            sx={{
              ...TYPOGRAPHY.subtitle2,
              mt: '15px',
            }}
          >
            All trips will be billed at a minimum of $60.00
          </Typography>
        </Modal>
        <img
          src={imageUrl}
          alt="vehicle image"
          style={{
            width: '100%',
            opacity: !failedToLoad && showPrices && !subTotalGeneral && !totalGeneral ? 0.5 : 1,
          }}
          data-cy="serviceLevelImage"
        />
        <Box
          p="16px"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: '100% 1 1',
          }}
        >
          <Stack direction="row">
            <Box mr="10px">
              <Typography
                sx={{
                  ...TYPOGRAPHY.title3,
                }}
                data-cy="serviceLevelImage"
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  ...TYPOGRAPHY.subtitle2,
                }}
                data-cy="serviceLevelClass"
              >
                {subtitle}
              </Typography>
            </Box>
            <Stack ml="auto">
              <Stack direction="row" data-cy="passengerCount">
                <SvgIcon
                  src="/assets/icons/icon_user.svg"
                  color={COLORS.GREY_MEDIUM_1}
                  width="20px"
                />
                <Typography
                  sx={{
                    ...TYPOGRAPHY.paragraph,
                    color: COLORS.GREY_MEDIUM_1,
                    ml: '10px',
                  }}
                >
                  {passengersAmount ? passengersAmount : 'N/A'}
                </Typography>
              </Stack>
              <Stack direction="row" data-cy="luggageCount">
                <SvgIcon
                  src="/assets/icons/icon_luggage.svg"
                  color={COLORS.GREY_MEDIUM_1}
                  width="19px"
                />

                <Typography
                  sx={{
                    ...TYPOGRAPHY.paragraph,
                    color: COLORS.GREY_MEDIUM_1,
                    ml: '10px',
                  }}
                >
                  {luggageAmount ? luggageAmount : 'N/A'}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Box mt="25px" mb="auto">
            <div dangerouslySetInnerHTML={{ __html: services }}></div>
          </Box>

          {!failedToLoad && showPrices && !subTotalGeneral && !totalGeneral && (
            <>
              <Box
                sx={{
                  maxWidth: '60px',
                  width: '100%',
                  height: '22px',
                  borderRadius: '4px',
                  backgroundColor: COLORS.GREY_LIGHT_6,
                  ...fillGlowingAnimation,
                }}
              />

              <Box
                sx={{
                  maxWidth: '150px',
                  width: '100%',
                  height: '18px',
                  borderRadius: '4px',
                  backgroundColor: COLORS.GREY_LIGHT_6,
                  mt: '4px',
                  ...fillGlowingAnimation,
                }}
              />

              <Box
                sx={{
                  maxWidth: '374px',
                  width: '100%',
                  height: '48px',
                  borderRadius: '16px',
                  backgroundColor: COLORS.GREY_LIGHT_6,
                  mt: '16px',
                  ...fillGlowingAnimation,
                }}
              />
            </>
          )}

          {failedToLoad && (
            <>
              <Typography
                sx={{
                  ...TYPOGRAPHY.subtitle2,
                }}
              >
                Couldn’t find any results..
              </Typography>
              <AppButton
                title="Search again"
                variant="outlined"
                additionalSx={{
                  mt: '10px',
                }}
                onClick={getVehicleClassOnDemand}
              />
            </>
          )}

          {showPrices && subTotalGeneral && totalGeneral && (
            <>
              <Box
                sx={{
                  mt: 'auto',
                  cursor: 'pointer',
                }}
                onClick={openModal}
                data-cy="serviceLevelQuote"
              >
                <Typography
                  sx={{
                    ...TYPOGRAPHY.title3,
                  }}
                >
                  {formatToUSD(subTotalGeneral)}
                </Typography>
                <Typography
                  sx={{
                    ...TYPOGRAPHY.subtitle2,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  data-cy="totalWithTaxesAndFees"
                >
                  {formatToUSD(totalGeneral)} total with taxes & fees
                  <SvgIcon src="/assets/icons/icon_arrowDown.svg" width="18px" />
                </Typography>
              </Box>
            </>
          )}

          {failedToLoad || callForAvailability ||
            (!(showPrices && !subTotalGeneral && !totalGeneral) && (
              <AppButton
                title="Select"
                additionalSx={{
                  mt: '10px',
                  borderRadius: '20px',
                  ...TYPOGRAPHY.paragraph,
                  fontSize: '16px',
                  color: 'white',
                }}
                type="button"
                onClick={onSelectVehicle}
                data-cy="reserveServiceBtn"
              />
            ))}

          {failedToLoad || (callForAvailability && (
            <a
              href={`tel:${PHONE_NUMBER_E164_FORMAT}`}
              style={{
                height: "48px",
                border: "1px solid #dadfdd",
                borderRadius: "16px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "16px",
                fontWeight: "600",
                color: "#212121",
                marginTop: "16px",
                textDecoration: "none",
              }}
            >
              <img
                src={'/assets/images/icon_phoneRinging.svg'}
                style={{
                  marginRight: "8px",
                }}
              />
              Call for availability
            </a>
          ))}

        </Box>
      </Box>
    );
  },
);

VehicleClassItem.displayName = 'VehicleClassItem';

export default VehicleClassItem;
