import CardStepItem from './CardStepItem';
import FormFieldLayout from '../../../../components/shared/formFieldRelated/FormFieldLayout';
import { BookRideFieldNames } from '../../types';
import RHFTextField from '../../../../components/shared/formFieldRelated/RHFTextField';
import React, { FC, ReactElement } from 'react';
import { Typography } from '@mui/material';
import { TYPOGRAPHY } from '../../../../theme/typography';
import { useFormContext } from 'react-hook-form';

interface Props {
  onContinue: () => void;
  onEdit: () => void;
  isEditing: boolean;
  displayEditButton: boolean;
}

const SpecialRequests: FC<Props> = ({
  onContinue,
  onEdit,
  isEditing,
  displayEditButton,
}): ReactElement => {
  const { watch } = useFormContext();

  const [specialRequest] = watch([BookRideFieldNames.SPECIAL_REQUESTS]);

  return (
    <CardStepItem
      title={`Special requests`}
      onContinue={onContinue}
      onEdit={onEdit}
      isEditing={isEditing}
      displayEditButton={displayEditButton}
      containerDataCy="addons-card"
      buttonDataCy="addons-continue-btn"
      editButtonDataCy="addons-edit-btn"
    >
      {!isEditing && (
        <Typography
          sx={{
            ...TYPOGRAPHY.paragraph,
            mt: '5px!important',
          }}
        >
          {specialRequest}
        </Typography>
      )}
      {isEditing && (
        <FormFieldLayout
          name={BookRideFieldNames.SPECIAL_REQUESTS}
          label=""
          dataCy="special-requests-textfield"
        >
          <RHFTextField
            name={BookRideFieldNames.SPECIAL_REQUESTS}
            placeholder="Any special requests or needs?"
            type="textarea"
            inputProps={{
              maxLength: 500,
            }}
          />
          <Typography
            sx={{
              ...TYPOGRAPHY.paragraph,
              mt: '15px',
            }}
            data-cy="charsLeft"
          >
            {(specialRequest && specialRequest.length) || 0}/500 characters
            including space
          </Typography>
        </FormFieldLayout>
      )}
    </CardStepItem>
  );
};

export default SpecialRequests;
